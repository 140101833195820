/* IPAD LANDSCAPE < 1280px
======================================================================================= */
@media only screen and (min-width : 1024px) and (max-width : 1280px) {
	#solution-application-carousel .item .item-content .picto {
		text-align: center;
		height: 40px;
		position: relative;
	}
}


/* IPAD LANDSCAPE < 1024px
======================================================================================= */
@media only screen and (max-width : 1024px) and (orientation:landscape) {
	/* HEADER */
	#get-estimate {
		display: inline-block;
	}
	.header-center #main-menu-search {
		width: 50%;
	}
	#main-menu-nav {
		display: block;
	}
	#solution-application-carousel .item .item-content{
		padding : 0px 20px;
		height:55%;
	}
		#solution-application-carousel .item .item-content .solution-application-title{margin-top : 10px;}
}
@media only screen and (max-width : 1024px) {
	.container {
		max-width: 1024px;
	}
	#project-solutions {
		padding-top: 30px;
	}
	#your-project {
		max-width: 512px;
	}	
	#our-solutions {
		max-width: 512px;
	}
	.isotope-container {
		margin: auto;
		display: block;
	}
	.isotope-container .grid-item {
		width: 65%;
	}
	.isotope-container .grid-item.column {
		width:34%;
	}
	#our-solutions-home.container {
		max-width: 800px;
	}
	.main-menu-center-nav ul li {
		margin-left: -30px;
	}
	#main-header .col-md-5.size {
		width: 50%;
	}
	#newsletter-button {
		width: 15%;
		padding: 5px;
		font-size: 10px;
	}
	.main-projet .les-plus .plus h3 {
		font-size: 17px;
	}
	.isotope-container .grid-item .item-img:after {
		margin-top: 2px;
	}
}

/* IPAD < 1024px (991 : bootstrap breakpoint)
======================================================================================= */
@media only screen and (max-width : 991px) {
	/* HEADER */
	header {
		border: none;
	}
	.container {
		max-width: 991px;
	}
	#main-header {
		margin: 15px 0;
	}
	#main-menu-nav {
		display: none;
	}
	#get-estimate {
		display: none;
	}
	.header-center #main-menu-search {
		margin: 0;
		width: 100%;
		padding: 12px 0;
	}
	#main-menu-search #searchform input {
		width: 100%;
		max-width: 70%;
	}
	#main-menu-search #searchform button {
		padding: 0 10px;
	}
	#burger-menu {
		margin-top: 17px;
		margin-right:1px;
	}
	#main-menu-mobile {
		top: 119px;
		height: 90vh;
	}
	/* SOLUTION-APPLICATION - HOME */
	#solution-application {
		margin: 0;
	}
	#solution-application-carousel .item .item-content {
		padding: 20px 10px;
	}
	#solution-application-carousel .item .item-content .picto {
		height: 55px;
	}
	#solution-application-carousel .slick-prev {
		left: 30%;
	}
	#solution-application-carousel .slick-next {
		right: 30%;
	}
	/* CONTENT PUSHS - HOME */
	#content-pushs-home .col-md-4:first-of-type {
		padding-left: 15px;
	}
	#content-pushs-home .col-md-4:last-of-type {
		padding-right: 15px;
	}
	#content-pushs-home .item-content {
		padding: 0 15px 60px;
	}
	#content-pushs-home .item-chapiste-cta {
		width: 80%;
		padding: 12px 15px;
	}
	/* NOS SOLUTIONS - HOME */
	#our-solutions-home-carousel .slick-prev {
		top: initial;
		bottom: -40px;
		left: 40%;
	}
	#our-solutions-home-carousel .slick-next {
		top: initial;
		bottom: -40px;
		right: 40%;
	}
	/* DISPATCH */
	#featured-content #featured-content-header {
		padding: 20px;
	}
	/* SEARCH */
	#search-form {
		margin: 20px auto 30px;
	}
	#search-results ul li .item-img {
		width: 25%;
	}
	#search-results ul li .item-content {
		width: 73%;
	}

	/* APPLICATION */
	.sommaire{display: none;}
	.main-application{margin-left: 0}

	/* LANDING PAGE */
	.landing{
		/* padding:0 5px; */
	}
	.landing-container{
		position:relative;
	}
	#estimate-baseline.landingPage #estimate-baseline-inner h1{
		padding-left:25px; 
		padding-right: 25px;
		bottom : 100px;
	}

	#estimate-baseline.landingPage #estimate-baseline-inner button{
		display: block;
		position : absolute;
		bottom: 20px;
		left:50%;
		transform: translateX(-50%);
	}
	.landing-container .layer-form{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0px;
		background: rgba(12,119,149,0.9);
		display:none;
		z-index:3;
	}
	.form-devis i{
		color:#fff;
		cursor: pointer;
		float:right;
		padding : 10px;
		display: block;
	}
	.form-devis{
		height:0;
		overflow: hidden;
		opacity: 0;
	}
	.form-devis.open-mobile{
		height : auto;
		position : absolute;
		top:0;
		left: 0;
		right:0;
		z-index:4;
		opacity: 1;
		padding:0;
	}
	.form-devis form{
		text-align: center;
		padding:0;
	}
	.form-devis form .select-wrapper, aside form input{
		vertical-align: top;
		height : 62px;
		max-width : 300px;
		width : calc(100% - 40px);
		display:inline-block;
		margin : 0 20px 10px;
	}
	.form-devis.open-mobile form, .form-devis.open-mobile p{background: none}
	.landing-type.row .col-xs-6{
		/* padding : 0 10px; */
	}
	.form-devis{display:none;}
	.image .carrou-image{
		margin-left:0;
		width : 100%;
	}
	.point{
		height:auto; 
		padding-bottom : 30px;
	}
	#estimate-baseline.landingPage{
		margin-right:-15px;
		margin-left:-15px;
	}
	.points .row:last-child .point {
		border-bottom : 1px solid #e6e6e6;
	}
	.points .row:last-child .point:last-child{
		border-bottom:0;
	}
	/* FOOTER */
	#footer-exergue p {
		padding: 0 100px;
	}
	#footer-cta .item a {
		width: 100%;
	}
	#footer-bottom nav .row {
		max-width: 100%;
		margin: 0;
	}

	/* Espace chapiste */
	.chapiste-column{
		width: 100%;
		display: block;
		margin:0;
	}
	.chapiste-column iframe{
		margin: 0 auto;
		min-width: 250px;
	}
}

/* IPAD  < 750px (750 = breakpoint bootstrap)
======================================================================================= */
@media only screen and (max-width : 768px) { 
	#your-project {
		max-width: 50%;
	}	
	#our-solutions {
		max-width:50%;
	}
	.isotope-container .grid-item {
		width: 100%;
	}
	.isotope-container .grid-item.column {
		width:100%;
	}
	.isotope-container .grid-item.column .item-img img {
		width: 104%;
	}
	.isotope-container .grid-item.column .item {
		flex-direction: row;
	}
	#content-references {
		padding-top: 30px;
	}
	.main-projet {
		margin-left: 0;
	}
	.main-projet .les-plus .plus h3 {
		font-size: 16px;
	}
	#solution-application-carousel2 .item-img.mobile {
		display: block;
	}
	 #solution-application-carousel2 .item-img {
	 	display: none;
	 }
	 #newsletter-button {
		display: none;
	}
	.isotope-container .grid-item.column .item-img:after { 
		margin-left: -9px;	
	}
	.indexv4 #solution-application-carousel2 .item .item-content {
		right: 50%;
		transform: translate(-50%,-50%);
		width: 80%;
		margin-right: 0;
	}
	.indexv3 #solution-application-carousel2 .item .item-content {
		left: 50%;
		transform: translate(-50%,-50%);
		width: 80%;
		margin-left: 0;
	}
	.references-grid .grid-item {
		width: 46%;
	}
	#solution-application {
		margin-top: 0!important;
	}
	#main-header .row .col-md-5 {
		width: 58.33333333%
	}
	.item-information .item-point {
		display: block;
	}
	.item-information p {
		text-align:  center;
	}
	.page-references-testimony .container-flex {
		flex-direction: column-reverse;
	}
	.page-references-testimony #ask-estimate.container {
		max-width: 100%;
	}
	.aside-testimony {
		width: 100%;
		margin: 0 0 30px;
		position: relative;
	}
	.aside-testimony .section-infos {
		display: none;
	}
	.arrow-mobile {
		display: inline-block;
		cursor: pointer;
		width: 20px;
		height: 20px;
		position: absolute;
		overflow: hidden;
		right: 20px;
	}	
	.arrow-mobile:before, .arrow-mobile:after {
		content: "";
		width: 100%;
		height: 2px;
		top: 50%;
		left: 50%;
		background: #155d70;
		position: absolute;
		transition: all 0.15s cubic-bezier(.42, 0, .58, 1);
		opacity: 1;
	}
	.arrow-mobile::before {
		transform: translate(-50%, -50%) rotate(90deg);
	}
	.arrow-mobile::after {
		transform: translate(-50%, -50%);
	}
	.arrow-mobile.expanded::before {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	.arrow-mobile.expanded::after {
		transform: translate(-50%, -50%) rotate(-90deg);
		opacity: 0;
	}
	.module-wysiwyg {
		padding-top: 20px;
	}

}
@media only screen and (max-width : 750px) {

	/*HEADER*/
	#social-networks .baseline{text-align: center;}
	#breadcrumb{display:none;}
	#main-header{margin : 0; height:46px;}
	.logo{padding : 5px 10px;}
	.logo img{width : 95px;}
	#main-menu-search{height:100%;padding:0 !important;margin-right:-1px; width:100%; text-align:right;}
	#header-center{padding : 0 10px; text-align: right;}
	#main-menu-search #searchform input{width : 100%; height:46px; background: #206373; border:0;padding:10px;color:#fff;}
	#main-menu-search #searchform button{height:46px; background: #206373; width:44px;border:0;}
	header .search-input-mobile{
		display: none;
		height:87vh; 
		background: #0c7795; 
		background: linear-gradient( to top, #009ac5, #002833);
		background: -moz-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background: -webkit-linear-gradient( top, rgba(0,154,197,0.95),rgba(0,40,51,0.95));
		background:     -ms-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background:      -o-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background:         linear-gradient( to top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		position:absolute; 
		left:15px;
		width:100%;
		z-index:10;
	}
	header .search-input-mobile input{
		background: #0c7795;
		background: rgba(0,0,0,0);
		color:#fff;
		height:80px;
		padding:20px;
		width:100%;
	}
	#main-header .row{position : relative;}
	#main-menu-mobile {
		top: 79px;
	}
	.header-center{position: initial}
	.search-btn-mobile {
		display: block;
		float:right;
		background: none;
		font-size: 24px;
	}
	.search-btn-mobile .fa {
		color: #0c7795;
		vertical-align: top;
	}
	#edit-submit--2{display: none;}
	#search-block-form--2 {
		position : absolute; 
		top : 46px;left:0;
		width : 100%;
		display: none;
		height:87vh; 
		background: #0c7795; 
		background: linear-gradient( to top, #009ac5, #002833);
		background: -moz-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background: -webkit-linear-gradient( top, rgba(0,154,197,0.95),rgba(0,40,51,0.95));
		background:     -ms-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background:      -o-linear-gradient( top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		background:         linear-gradient( to top, rgba(0,154,197,0.95), rgba(0,40,51,0.95));
		position:absolute; 
		width:100%;
		z-index:10;
	}
	.search-close {
		position: absolute;
		right: 15px;
		top: 70px;
		z-index: 10;
		transition: all .5s ease-in-out;
		cursor: pointer;
		display: none;
		width: 30px;
		height: 25px;
	}
		.search-close span {
			display: block;
			position: absolute;
			height: 2px;
			width: 100%;
			background: #ffffff;
			opacity: 1;
			left: 0;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: .25s ease-in-out;
			-moz-transition: .25s ease-in-out;
			-o-transition: .25s ease-in-out;
			transition: .25s ease-in-out;
			transform-origin: left center;
		}
		.search-close span:nth-child(1) {
			-webkit-transform: rotate(45deg);
			-moz-transform: rotate(45deg);
			-o-transform: rotate(45deg);
			transform: rotate(45deg);
			top: 0px;
			left: 0px;
		}
		.search-close span:nth-child(2) {
			-webkit-transform: rotate(-45deg);
			-moz-transform: rotate(-45deg);
			-o-transform: rotate(-45deg);
			transform: rotate(-45deg);
			top: 21px;
			left: 0px;
		}
	#search-block-form--2 .form-item input {
		background: none;
		border:0;
		color:#fff;
		width:100%;
		font-size: 22px;
	}
	#search-block-form--2 .form-item { 
		width:100%;
		padding : 20px;
	}
	#search-block-form--2 .form-actions {
	display: none;
	}
	.burger-container{padding: 0 5px;}
	#burger-menu{margin-top:12px;}

	#search-block-form--2 .form-item input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		color: #ffffff;
		font-size: 22px;
		opacity: 1;
	}
	#search-block-form--2 .form-item input::-moz-placeholder { /* Firefox 19+ */
		color: #ffffff;
		font-size: 22px;
		opacity: 1;
	}
	#search-block-form--2 .form-item input:-ms-input-placeholder { /* IE 10+ */
		color: #ffffff;
		font-size: 22px;
		opacity: 1;
	}
	#search-block-form--2 .form-item input:-moz-placeholder { /* Firefox 18- */
		color: #ffffff;
		font-size: 22px;
		opacity:}

	h2.blue{font-size: 18px;}

	#solution-application-title h2{font-size : 24px;}
	#estimate-baseline .layer{background : #0c7795; opacity: 1}

	/* MEGA GABARIT*/
	#ask-estimate a p{
		font-size: 20px;
	}
	#main-banner{
		height : 450px;
	}
	#main-banner #links{
		display : none;
	}
	.table-responsive{
		margin : 0 15px 40px;
		border:0;
		padding : 0;
		width : calc(100% - 30px);
	}
	.filter-glossaire{
		font-size : 12px;
	}
	#mega-gabarit-modules .module-links{
		padding : 0 20px;
	}

	/* SOLUTIONS */
	.main-container{padding:0;}
	#estimate-baseline.solutionPage{
		height : 450px;
		background: #0c7795;
	}
		#estimate-baseline.solutionPage h1{font-size: 22px;}
		#estimate-baseline.solutionPage p {font-size:14px;}
		#estimate-baseline.solutionPage h2{
			font-size: 12px; 
			padding : 0 40px;
		}
		#estimate-baseline.solutionPage .tools{
			padding-top: 10px;
		}
	.solutions-onglets{
		margin-left:-15px;
		margin-right: -15px;	
	}
	.solutions-onglets.fixed{
		width : auto; 
		width : calc(100% + 30px);
	}
		.solutions-onglets a,.solutions-onglets a:focus, .solutions-onglets a:visited{
			font-size: 13px; 
			color:#3e3f3f; 
			border-top: #0c7795; 
			padding : 10px 12px;
		}
		.solutions-onglets a:hover{
			border : 1px solid #0c7795;
		}
	.les-plus h2{
		font-size: 21px;
		width: 100%;
	}
	.les-plus .plus h3{font-size: 21px;}
	.les-plus .plus{
		min-height: 0; 
		padding-top : 15px; 
		padding-bottom : 15px;
	}
	.les-plus .plus::after{
		left:50%; 
		transform: translateX(-50%);
	}
	.les-plus .row:last-child .plus::after{display: block;}
	.les-plus .ask-devis{
		margin-top : 30px; 
		margin-bottom : 20px; 
		padding : 30px 20px; 
		height:auto;
	}
	.les-plus .ask-devis img{
		height : 30px; 
		margin-bottom : 5px;
	}
	.caracteristique dl{margin-bottom:0;}
	.doc{margin-top : 30px;}
	.temoignage .slick-slide{
		position : relative; 
		box-shadow:  0px 2px 10px rgba(0,0,0,0.1)
	}
	.temoignage .quote-text, .temoignage .quote-img{
		display: block; 
		width : 100%; 
		margin : 0 auto;
	}
	.temoignage .quote-text.no-img{
		top:0;
		position: relative;
	}
	.temoignage .quote-text {
		position : absolute; 
		top : 180px ; 
		left:0; 
		padding-top : 0;
	}
		.temoignage .quote-text .picto-quote{
			border: 3px solid #fff; 
			line-height : 45px;
		}
		.temoignage .quote-text p.name{
			font-size:14px;
		}
	.temoignage .quote-img{
		margin-bottom : 200px; 
		height : 200px; 
		overflow: hidden;
	}
	.temoignage .quote-img img{
		margin : 0 auto; 
		height : 100%;
	}

	/*APPLICATION*/
	.main-app-wrapper{
		padding-top : 20px;
	}
	.main-application{
		border:0;
		padding : 0;
	}
	.main-application .multiple-inline-element{
		display: block;
		width:100%;
		min-height:0;
	}

	.main-application .les-plus .plus-img{
		display: none;
	}
	.main-application .les-plus .plus-ask-devis{
		display: block;
	}
		.main-application .les-plus .plus-ask-devis p{color:#fff;}
	.doc-application{
		display:none;
	}

	/*LANDING PAGE */
	.landing .landing-type .top{
		padding : 15px;
		height:85px;
	}
	.points .multiple-inline-element, .landing-type .multiple-inline-element{display : inline-block; width : 100%; max-width: 100%; margin:0; padding:0;}
		.landing .landing-type .top svg, .landing .landing-type .top img{
			height : 25px;
		}
	.landing .landing-type p{
		padding: 10px 0 5px;
	}
	.avantage ul{
		padding-left:0;
	}
	.image .carrou-image{
		margin-bottom : 60px;
	}
	.image .item .img-container img{
		width : 100%;
	}
	.image .item .legend{
		display: block; 
		margin-top : 0px;
		padding-bottom : 10px;
	}
	.image .more ul li{
		padding-left:15px;
	}
	.landing h2.blue{
		font-size: 18px;
	}
	.point h3{
		font-size: 14px;
	}
	.point .picto{
		height : 100px; 
		width : 100px;
		line-height: 130px;
	}
	.point .picto img{
		max-width : 70px;
	}
	.point .picto svg {
		max-height : 40px;
	}

	/* DEVIS */
	#estimate-form .picto {
		margin : 50px;
	}
	#estimate-form .trait {
		width : 202px;
		bottom: 78px;
	}
	#estimate-form .picto .picto-img {
		height : 112px;
		width : 112px;
		padding : 10px 20px;
	}
	#estimate-form .picto .picto-img svg {
		width : 35px;
		height: 36px;
	}
	#estimate-form .picto .picto-title span{font-size : 17px; line-height:12px;}
	#estimate-form .picto .etape{ margin-top : 10px; font-size: 12px;}
	#estimate-form form{padding : 0 15px;}
	.estimate-form-newsletter {
		width: 100%;
	}
	.estimate-form-question{margin-bottom :10px ;}
	#estimate-form form .select-wrapper, #estimate-form  form input[type="text"],#estimate-form  form input[type="email"],#estimate-form  form input[type="tel"] {
		height : 62px;
		width : 100%;
		display:inline-block;
	}
	#estimate-form form select{height:62px;}
	#estimate-form form label{display : none;}
	.estimate-form-question .fieldgroup label{display: inline-block !important; font-size:12px;}
	#estimate-form form label.contact{display : block;}
	#estimate-form .btn-submit{
		display : block;
		float:none;
		margin : 10px auto;
		padding : 17px 37px 19px;
		width: 100%;
		max-width: 300px;
	}
	#estimate-form .form-part{
		margin-bottom : 35px;
	}

	/*SEARCH*/
	#search-results{margin-bottom : 35px;}
	.content-pushs-home-search{display:none;}

	/*erreur*/
	#error404 #short-links ul li a{padding : 16px 20px;}

	/* FOOTER */	
	#lafarge-logo{
		position: relative;
		right : 0;
		top:0;
		transform: none;
		padding: 17px 65px 30px;
		display: inline-block;
		border-top : 1px solid #fff;
		width : 225px;
	}
	#footer-bottom{
		margin-top : 30px;
	}
	.main-projet .les-plus .plus {
		min-height: auto;
	}
	#estimate-baseline.project-page #estimate-baseline-inner p {
		margin: auto;
	}
	.main-projet article .projet-content {
		padding: 0;
	}
	.main-projet .wysiwyg-text {
		border:none;
	}
}

/* IPHONE < 480px
======================================================================================= */
@media only screen and (max-width : 480px) {
	/* HEADER */
	header {
		border: none;
	}
	#main-menu-nav {
		display: none;
	}
	#get-estimate {
		display: none;
	}
	#main-menu-mobile #get-estimate-mobile a{
		font-size:14px;
		background-size: 45px;
		padding-left:70px;
	}
		#main-menu-mobile #newsletter-button-mobile a{
		font-size:14px;
		background-size: 45px;
		padding-left:70px;
	}
	.header-center #main-menu-search {
		margin: 0;
		padding: 0;
	}
	#main-menu-search #searchform input {
		width: 100%;
		max-width: 70%;
	}
	#main-menu-search #searchform button {
		padding: 0 10px;
	}
	#main-menu-mobile {
		width: 100vw;
		height: 95vh;
		background: rgba(11, 119, 148, 0.9);
		position: absolute;
		left: 0;
		top: 79px;
		z-index: 2;
		padding: 15px 15px;
	}
	#main-menu-mobile nav ul li a {
		font-size: 16px;
	}
	#main-menu-mobile-footer li {
		padding: 0 10px 0 0;
	}
	#main-menu-mobile-footer li a {
		width: 30px;
		height: 30px;
	}
	#main-menu-mobile-footer li a .fa {
		font-size: 16px;
	}
	.header-logo-lafarge {
		width: 100px;
	}
	/* SOLUTION-APPLICATION - HOME */
	#solution-application {
		margin-top: 0px;
	}
	#solution-application-carousel .item .item-content {
		padding: 20px 70px;
	}
	#content-pushs-home .col-md-4 {
		padding: 0 !important;
	}
	#content-pushs-home .item {
		box-shadow:  none;
	}
	#solution-application-carousel .slick-dots {
		bottom: -100px;
	}
	#solution-application-carousel .slick-prev {
		left: 0;
		bottom: 120px;
	}
	#solution-application-carousel .slick-next {
		right: 0;
		bottom: 120px;
	}
	#solution-application-title h1 {
		padding: 30px 5%;
	}
	/* NOS SOLUTIONS - HOME */
	#our-solutions-home.container {
		margin-top: 0px;
	}
	#our-solutions-home h2 {
		text-align: center;
	}
	#our-solutions-home-carousel .item {
		padding: 0 60px;
		text-align: center;
	}
	#our-solutions-home-carousel .slick-prev {
		top: initial;
		bottom: 50%;
		margin-bottom: -11px;
		left: 5%;
	}
	#our-solutions-home-carousel .slick-next {
		top: initial;
		bottom: 50%;
		margin-bottom: -11px;
		right: 5%;
	}
	#solution-application-carousel2 .item .item-content {
		width: 100%;
	}
	#solution-application-carousel2 .item .item-content h2 {
		font-size: 20px;
		line-height: 20px;
	}
	#project-solutions h2 {
		font-size: 20px;
	}
	#content-references {
		padding-top: 30px;
	}
	.isotope-container .grid-item .item {
		flex-direction: column;
		width: 100%;
	}
	.isotope-container .grid-item.column .item {
		flex-direction: column;
	}
	.isotope-container .grid-item .item-img {
		width: 100%;
	}
	.isotope-container .grid-item .item-content {
		width: 100%;
	}
	#content-references .button {margin-bottom: 50px;}
	.isotope-container .grid-item.column .item-img img{
		width:100%;
	}
	/* CONTENT PUSHS - HOME */
	#content-pushs-home .item-cta{left:20px;}
	#content-pushs-home .item {
		min-height: 370px;
	}
	#content-pushs-home .pushs-carrousel-solution2 .item {
		min-height: 380px;
	}
	#content-pushs-home .chapiste .item {
		min-height: 280px;
	}
	.item-picto {
		margin: 20px auto;
	}
	.item-picto img {
		width: 25px;
	}
	.item-chapiste-cta {
		bottom: 20px;
	}
	.points .multiple-inline-element {
		max-width: 100%;
		min-height: auto;
		margin-bottom: 10px;
	}

	/* DISPATCH */
	#featured-content #featured-content-header h1 {
		padding: 0;
		font-size: 22px;
		line-height: 26px;
	}
	#dispatch-items .col-md-6:nth-child(odd), #dispatch-items .col-md-6:nth-child(even) {
		padding-left: 0;
		padding-right: 0;
	}
	#dispatch-items {
		padding: 20px 15px 0px;
	}
	#dispatch-items .item {
		box-shadow: none;
		min-height: 410px;
	}
	#dispatch-items .item-content{padding : 0 20px 50px;}
	#dispatch-items .item-cta{left:20px;}
	#ask-estimate.container {
		padding: 0;
	}
	#ask-estimate.container a {
		padding: 20px 0;
	}
	#ask-estimate a p {
		font-size: 18px;
	}
	#ask-estimate img {
		width: 35px;
	}

	/* MEGA GABARIT */
	#mega-gabarit-modules #content-pushs-home .col-md-4 {
		padding-right: 15px;
		padding-left: 15px;
		margin-bottom: 20px;
	}
	/* SEARCH */
	#search-form input {
		width: 85%;
	}

	#search-results ul li .item-img {
		display: block;
		width: 100%;
		border: 1px solid #f2f2f2;
		margin-bottom: 5px;
		text-align: center;
		padding: 0 0 15px;
		height:200px;
	}
	#search-results ul li .item-img img {
		border: none;
		transform: translateY(-15%);
	}
	#search-results ul li .item-content {
		display: block;
		width: 100%;
	}
	
	#search-page #content-pushs-home h2 {
		padding: 0 15px;
	}
	#search-page #content-pushs-home .row {
		padding: 0;
	}
	.pagination {
		margin: 0;
	}
	/* DEVIS */
	#estimate-baseline-inner p {
		width: 100%;
		max-width: 100%;
		padding: 0 15px;
	}
	#estimate-form #estimateForm {padding:0 5px;}
	#estimate-form .picto{margin : 30px 15px;}
	#estimate-form .picto:first-child{margin-left:0;}
	#estimate-form .picto:last-child{margin-right:0;}
	#estimate-form .trait{width : 133px; bottom:58px;}

	#estimate-form .picto {
		margin : 50px;
	}
	#estimate-form .trait {
		width : 202px;
		bottom: 78px;
	}
	#estimate-form .picto .picto-img {
		height : 112px;
		width : 112px;
		padding : 10px 20px;
	}
	#estimate-form .picto .picto-img svg {
		width : 35px;
		height: 36px;
	}
	#estimate-form .picto .picto-title span{font-size : 17px; line-height:12px;}
	#estimate-form .picto .etape{ margin-top : 10px; font-size: 12px;}
	#estimate-form form{padding : 0 15px;}
	.estimate-form-question{margin-bottom :10px ;}
	#estimate-form form .select-wrapper, #estimate-form  form input[type="text"],#estimate-form  form input[type="email"],#estimate-form  form input[type="tel"] {
		width : 100%;
		display:inline-block;
	}
	#estimate-form form label{margin-bottom: 5px;}
	.estimate-form-question .fieldgroup label{display: inline-block !important; font-size:12px;}
	#estimate-form form label.contact{display : block;}
	#estimate-form .btn-submit{
		display : block;
		float:none;
		margin : 10px auto;
		padding : 17px 37px 19px;
		width: 100%;
		max-width: 300px;
	}
	#estimate-form .form-part{
		margin-bottom : 35px;
	}

	/* ERROR 404 */
	#error404 {
		margin-top: 0;
	}
	#error404 .row {
		padding: 30px 0;
	}
	#error404 #picto{
		display:none;
	}
	#error404 #short-links ul li {
		display: block;
		padding: 10px 6px;
	}
	#error404 #short-links ul li a {
		display: block;
		width: 64%;
		margin: 0 auto;
		padding: 10px 30px;
		text-align: left;
	}
	/* SOLUTIONS */
	#estimate-baseline {
		height: 440px;
	}
	#estimate-baseline.solutionPage p {
		padding: 0;
	}
	.solutions-onglets a, .solutions-onglets a:focus, .solutions-onglets a:visited{padding :7px 5px;}
	.solutions-onglets .item-chapiste-cta {
		font-size: 12px;
	}
	.les-plus .plus h3{
		font-size: 17px; 
		font-weight: 700;
	}
	.les-plus .plus span{
		height:40px; 
		width : 40px; 
		padding : 11px 12px;
	}
	.les-plus .plus span svg{
		height:17px; 
		width : 17px;
	}
	.les-plus .ask-devis{font-size: 17px;}
	.module-accordion.caracteristique dt {
		font-size : 12px; 
		padding-left:20px;
	}
		.module-accordion.caracteristique dt a {
			font-size : 12px; 
		}
	.doc{
		background: none;
		padding :0;
	}
	.doc p {
		font-size: 17px; 
		text-align: left; 
		padding: 0 10px;
		padding-top: 10px;
	}
	.doc a{
		/* background: #0c7795;  */
		padding : 15px 35px; 
		display: block; 
		text-align: left;
	}
	.doc a svg{
		height : 52px; 
		float:left; 
		margin-right : 20px; 
		margin-top :-7px;
	}
	.diaporama{
		margin : 2px -15px 0 -15px; 
		margin-top : 127px;
	}
	.realisation .slick-list{
		margin-left:-15px; 
		margin-right: -15px;
	}
	.realisation .module-gallery-carrousel{
		position: absolute; 
		top : 70px; 
		left:0; 
		width : 100%;
		overflow: hidden;
		margin: 0;
	}
	.module-gallery-carrousel .slick-slide{
		margin : 2px;
		height :90px;
	}
	.temoignage .quote-text{padding : 0 20px;}
	#content-pushs-home .pushs-carrousel-solution .slick-list{
		margin-left:-15px; 
		margin-right: -15px;
	}
	#content-pushs-home{margin : 0;}
	.main-container.container{margin-bottom : 20px;}
	.item-wrapper {
		padding: 0;
	}
	.les-plus .plus {
		min-height: auto;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	/*APPLICATION*/
	.main-application {
		padding: 15px;
	}
	.main-application ol{padding-left:0;}
	.main-application ol li{padding : 0 0 30px 0;}
	.main-application ol li::before{
		display: block;
		position: relative;
		margin : 0 auto 10px;
	}
	.main-application .les-plus{
		padding : 0;
	}
	#content-pushs-home .slick-list{margin-left:-15px; margin-right:-15px;}
	#content-pushs-home .pushs-carrousel-solution2 .item-content{padding : 0 20px 30px;}
	#content-pushs-home .pushs-carrousel-solution2 .item-cta{left:20px;}
	#content-pushs-home.content-pushs-home-mega {
		margin: 20px 0 30px 0;
		padding: 0;
	}
	.paragraphs-item-bloc-description-appli .multiple-inline-element {
		width: 100%;
		min-height: auto;
	}

	/*LANDING PAGE*/
	#estimate-baseline.landingPage #estimate-baseline-inner h1{font-size: 18px;}
	.landing .landing-type .top{font-size: 12px;}
	.avantage h2{font-size : 17px;}
	.landing-container {
		margin-top: 0;
	}
	.landing-wrapper .paragraphs-item-bloc-description-appli .multiple-inline-element {
		width: 100%;
		min-height: auto;
	}
	.multiple-element h3 {
		width: 100%;
	}

	#featured-content {
		background-position: center right;
	}
	#main-banner {
		min-height: 400px;
	}
	.landing-wrapper .module-wysiwyg {
		max-width: 100%;
	}

	/* FOOTER */
	#footer-bottom-nav .nav-pill {
		padding: 35px 0 70px;
	}
	#footer-exergue {
		padding: 20px 0;
	}
	#footer-exergue p {
		padding: 0;
	}
	#footer-columns .col-md-8 {
		margin-bottom: 15px;
	}
	#footer-cta .item a {
		margin-bottom: 15px;
	}
	#footer-bottom-nav li{
		display: block;
	}
	#footer-bottom nav ul li a {
		border: none;
		padding: 5px 0;
	}
	#lafarge-footer-link {
		display: block;
	}
	#lafarge-logo {
		bottom: 30px;
		left: 0;
		right: inherit;
		top: inherit;
	}
	.arrow-right, .arrow-left, .arrow-up {
		display: none;
	}
	#content-references h2 {
		text-align: center;
		padding-bottom: 20px;
	}
	#our-solutions .slick-dots {
		bottom: -30px;
	}
	.main-projet article {
		padding: 30px 0 0 0;
	}
	.main-projet h1 {
		font-size: 26px;
		text-align: center;
	}
	.main-projet ul {
		padding-left: 20px;
		margin-bottom: 20px;
	}
	.main-projet p {
		margin-bottom: 20px
	}
	.isotope-container .grid-item.column .item-img:after { 
		margin-left: -10px;	
	}
	.isotope-container .grid-item .item-img:after {
		margin-top: 32px; 
	}
	.isotope-container .grid-item:last-child .item-img:after {
		margin-top: -51px;
	}
	.indexv3 #solution-application-carousel2 .item .item-content {
		left: initial;
		transform: translateY(-50%);
		width: auto;
		right: initial;
	}
	.indexv4 #solution-application-carousel2 .item .item-content {
		left: initial;
		transform: translateY(-50%);
		width: auto;
		right: initial;
	}
	.module-blockquote {
		margin: 0;
		padding: 10px 0;
	}
	.module-blockquote .flex-blockquotes {
		flex-wrap: wrap;
		justify-content: center;
	}
	.button-devis {
		flex-wrap: wrap;
		margin: 20px 0;
	}
	.button-devis .devis-link {
		margin: 10px 0;
		width: 100%;
	}
	.button-devis .devis-link a {
		padding: 20px 80px;
	}
	#main-titre h1 {
		font-size: 30px;
	}
	.references-grid .grid-item {
		width: 100%;
	}
	.main-projet .conseil .references-grid .grid-item {
		width: 100%;
	}
	.module .item-cta2 {
		padding: 10px 18px;
	}
	.main-projet .item-cta2 {
		padding: 10px 18px;
	}
	.main-projet article.conseil {
		margin: 0;
	}
}
@media only screen and (max-width : 375px) {
	.isotope-container .grid-item .item-img:after {
		margin-top: 17px; 
	}
	.isotope-container .grid-item:last-child .item-img:after {
		margin-top: -36px;
	}
}	
/* IPHONE LANDSCAPE < 768px
======================================================================================= */
@media only screen and (max-width : 768px) and (orientation: landscape) {
	/* HEADER */
	#main-menu-nav {
		display: none;
	}
	div#get-estimate a {
		padding: 7px 15px;
	}
	/* DISPATCH */
	#dispatch-items .col-md-6:nth-child(odd), #dispatch-items .col-md-6:nth-child(even) {
		padding-left: 15px;
		padding-right: 15px;
	}
	/* ERROR 404 */
	#error404 {
		margin-top: 0;
	}
	#error404 .row {
		padding: 50px 0;
	}
	/* FOOTER */
	#footer-cta {
		text-align: center;
	}
	#footer-cta .item {
		width: 45%;
		display: inline-block;
		vertical-align: top;
		margin: 0 !important;
	}
	#footer-cta .item a {
		min-height: 115px;
	}
}